import { createTheme } from "@mui/material/styles"
import { rem, lighten, darken } from "polished"

const theme = createTheme({
  palette: {
    primary: {
      light: lighten(0.1, "#252D41"),
      main: `#252D41`,
      dark: darken(0.1, "#252D41"),
    },
    secondary: {
      light: lighten(0.1, "#D5D5D5"),
      main: `#D5D5D5`,
      dark: darken(0.1, "#D5D5D5"),
    },
    text: {
      secondary: `#fff`,
    },
  },
  shape: {
    borderRadius: "20px",
  },
  typography: {
    fontFamily: "'Playfair Display', serif, 'Red Hat Text', sans-serif",
    h1: {
      fontFamily: `Playfair Display`,
    },
    h2: {
      fontFamily: `Playfair Display`,
      fontSize: `${rem(48)}`,
    },
    h3: {
      fontFamily: `Playfair Display`,
      marginBottom: `17px`,
    },
    h4: {
      fontFamily: `Playfair Display`,
      marginBottom: `20px`,
    },
    h5: {
      fontFamily: `Playfair Display`,
      marginBottom: `15px`,
    },
    h6: {
      fontFamily: `Playfair Display`,
    },
    subtitle1: {
      fontFamily: "'Red Hat Text', sans-serif",
    },
    subtitle2: {
      fontFamily: "'Red Hat Text', sans-serif",
    },
    body1: {
      fontSize: `${rem(17)}`,
      fontFamily: "'Red Hat Text', sans-serif",
    },
    body2: {
      fontSize: `${rem(17)}`,
      fontFamily: "'Red Hat Text', sans-serif",
    },

    button: {
      fontFamily: "'Red Hat Text', sans-serif",
      textTransform: `none`,
    },
    caption: {
      fontFamily: "'Red Hat Text', sans-serif",
    },
    overline: {
      fontFamily: "'Red Hat Text', sans-serif",
    },
    a: {
      fontFamily: "'Red Hat Text', sans-serif",
      textTransform: `none`,
    },
  },
})
export default theme
