import React from "react"
import { Link } from "gatsby"
import { Box, useTheme } from "@mui/material"

import { StyledLogoWrapper, StyledLogoText } from "./Layout.styled"

import Logo from "../../images/logo.svg"

const Header = () => {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      ml={{ xs: `10px`, md: `30px` }}
    >
      <StyledLogoWrapper component={Link} to="/">
        <Box
          component="img"
          src={Logo}
          alt="BPG Logo"
          p={{ xs: `12px` }}
          m={0}
        />
      </StyledLogoWrapper>
      <StyledLogoWrapper component={Link} to="/" color="primary" disableRipple>
        <StyledLogoText
          sx={{ color: theme.palette.primary.main }}
          style={{ fontFamily: "'Avenir', sans-serif" }}
        >
          BRAVO PSYCHOLOGICAL GROUP
        </StyledLogoText>
      </StyledLogoWrapper>
    </Box>
  )
}

export default Header
