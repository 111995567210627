import {
  styled,
  AppBar,
  Box,
  ButtonBase,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"

import { rem } from "polished"

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#fff",
  minHeight: `${rem(70)}`,
  boxShadow: `0px 3px 14px rgba(0,0,0,0.05)`,
  [theme.breakpoints.up("xs")]: {
    minHeight: `initial`,
  },
}))

const StyledAppBarMaxWidth = styled(Box)(({ theme }) => ({
  maxWidth: `${rem(1366)}`,
  minWidth: `100%`,
  margin: ` 0 auto`,
  [theme.breakpoints.up("xs")]: {
    width: `auto`,
  },
}))
const StyledLogoWrapper = styled(ButtonBase)(({ theme }) => ({
  height: `${rem(70)}`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  textDecoration: `none`,
  color: theme.palette.primary.main,
}))

const StyledLogoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.up("xs")]: {
    fontSize: `${rem(13)}`,
  },
}))

const StyledMenuItem = styled(ButtonBase)(() => ({
  color: `#000`,
  fontFamily: `'Red Hat Display', sans-serif`,
  fontSize: `${rem(15)}`,

  textDecoration: `none`,
  paddingBottom: `${rem(8)}`,
  margin: `${rem(0)} ${rem(20)}`,
}))
const StyledMenuButtonItem = styled(ButtonBase)(() => ({
  color: `#fff`,
  backgroundColor: `#252D41`,
  fontFamily: `'Red Hat Display', sans-serif`,
  fontSize: `${rem(15)}`,
  textDecoration: `none`,
  padding: `${rem(8)} ${rem(18)}`,
  margin: `${rem(0)} ${rem(20)}`,
  borderRadius: `${rem(15)}`,
}))

const StyledList = styled(List)(() => ({
  backgroundColor: `#252D41`,
}))
const StyledListItem = styled(ListItem)(() => ({
  display: `block`,
  paddingTop: `${rem(10)}`,
  paddingBottom: `${rem(10)}`,
  paddingLeft: `${rem(34)}`,
  paddingRight: `${rem(86)}`,
}))

const StyledListItemText = styled(ListItemText)(({ buttonIsActive }) => ({
  color: `#fff`,
  borderBottom: `${buttonIsActive ? "2px solid white" : "initial"}`,
}))

export {
  StyledAppBar,
  StyledAppBarMaxWidth,
  StyledLogoWrapper,
  StyledMenuButtonItem,
  StyledMenuItem,
  StyledLogoText,
  StyledList,
  StyledListItem,
  StyledListItemText,
}
