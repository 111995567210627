import React, { useState } from "react"
import cuid from "cuid"
import { Link, navigate } from "gatsby"
import { rem } from "polished"
import {
  Grid,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  Button,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"

import {
  StyledAppBar,
  StyledAppBarMaxWidth,
  StyledLogoWrapper,
  StyledMenuItem,
  StyledMenuButtonItem,
  StyledLogoText,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from "./Layout.styled"

import Logo from "./Logo"

const navData = [
  {
    title: "About Us",
    href: "/about-us",
  },
  {
    title: "PremierCare",
    href: "/premiercare",
  },
  {
    title: "Services",
    href: "/services",
  },
  {
    title: "Our Team",
    href: "/our-team",
  },
  {
    title: "Careers",
    href: "/careers",
  },
  {
    title: "Forms",
    href: "/forms",
  },
]

const Header = () => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.between("xs", "lg"))

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const handleMobileNavigation = href => {
    navigate(href)
    setState({ ...state, ["right"]: false })
  }

  return (
    <>
      <StyledAppBar position="sticky" elevation={2}>
        <StyledAppBarMaxWidth>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Logo />
            </Grid>
            <Grid item>
              {matches ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer("right", true)}
                  size="large"
                >
                  <MenuIcon color="primary" />
                </IconButton>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="70px"
                  mr={{ xs: "30px" }}
                >
                  <Box>
                    {navData.map(el => {
                      return (
                        <StyledMenuItem
                          key={cuid()}
                          component={Link}
                          to={el.href}
                          activeStyle={{ borderBottom: "2px solid #252D41" }}
                          style={{
                            marginLeft: `13px`,
                            textTransform: `none`,
                            transition: "all 200ms",
                            borderBottom: "2px solid #fff",
                            position: "relative",
                            top: "7px",
                          }}
                          disableFocusRipple
                          disableRipple
                        >
                          {el.title}
                        </StyledMenuItem>
                      )
                    })}
                    <StyledMenuButtonItem
                      component={Link}
                      to="/contact-us"
                      variant="contained"
                      color="primary"
                      activeStyle={{ backgroundColor: "#D5D5D5" }}
                      sx={{ marginLeft: `13px`, borderRadius: "50px" }}
                      disableFocusRipple
                      disableRipple
                    >
                      Contact Us
                    </StyledMenuButtonItem>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </StyledAppBarMaxWidth>
      </StyledAppBar>
      <Drawer
        PaperProps={{ style: { backgroundColor: `#252d41` } }}
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box
          height={`${rem(126)}`}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="flex-end">
            <Box
              sx={{
                height: "70px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={toggleDrawer("right", false)}
                size="large"
              >
                <CloseIcon style={{ color: `white` }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <StyledList>
          {navData.map((el, index) => (
            <StyledListItem
              key={index}
              component={Button}
              onClick={() => handleMobileNavigation(el.href)}
            >
              <StyledListItemText primary={el.title} />
            </StyledListItem>
          ))}
          <StyledListItem
            component={Button}
            onClick={() => handleMobileNavigation(`/contact-us`)}
          >
            <StyledListItemText primary="Contact Us" />
          </StyledListItem>
        </StyledList>
      </Drawer>
    </>
  )
}

export default Header
