import React from "react"
import { navigate } from "gatsby"
import { rem } from "polished"
import { Grid, Box, Typography, ButtonBase } from "@mui/material"

import Logo from "../../images/logo.svg"

const Footer = () => {
  return (
    <Box
      bgcolor="#fff"
      pt={{ xs: `${rem(18)}`, md: `${rem(105)}` }}
      pb={{ xs: `initial`, md: `${rem(44)}` }}
      component="footer"
    >
      <Grid container>
        <Grid item md="4"></Grid>
        <Grid item xs="12" md="4">
          <Box display="flex" justifyContent="center">
            <ButtonBase onClick={() => navigate("/")}>
              <Box
                component="img"
                src={Logo}
                alt="BPG Logo"
                p={`12px 0 12px 12px`}
                m={0}
              />
            </ButtonBase>
          </Box>
        </Grid>
        <Grid item xs="12" md="4">
          <Box
            style={{ height: `70px` }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography paragraph color="primary">
              {`© 2021 Bravo Psychological Group`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
